import { mapGetters } from "vuex"
export default {
    computed: {
        ...mapGetters("crypto", ["isConnected"])
    },
    watch: {
        isConnected(newVal, oldVal) {
            if (newVal === true && oldVal !== newVal) {
                this.pageReady()
            } else if (newVal === false) {
                // this.$swal(`${vm.$t("errors.connectWalletError")}`)
                // this.$router.push({ name: "academy" })
            }
        }
    }
}
