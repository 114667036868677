import axios from "axios"
const nftApi = axios.create({
    baseURL: `${process.env.VUE_APP_NFT_API_URL}`,
    headers: {
        common: {
            "Content-Type": "application/json"
        }
    }
})

const nftsApi = {
    nfts(params) {
        return nftApi.get("nfts", { params: params })
    },
    nft(contractAddress, tokenId) {
        return nftApi.get(`nfts/${contractAddress}/${tokenId}`)
    },
    refreshMetadata(contractAddress, tokenId) {
        return nftApi.put(`nfts/${contractAddress}/${tokenId}`)
    }
}

export default nftsApi
