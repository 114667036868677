export const getNftLvl = (contractAddress) => {
    switch (contractAddress.toLowerCase()) {
        case process.env.VUE_APP_CRYPTO_CONTRACT_NFT_0.toLowerCase():
            return 0
        case process.env.VUE_APP_CRYPTO_CONTRACT_NFT_1.toLowerCase():
            return 1
        case process.env.VUE_APP_CRYPTO_CONTRACT_NFT_2.toLowerCase():
            return 2
        case process.env.VUE_APP_CRYPTO_CONTRACT_NFT_3.toLowerCase():
            return 3
        case process.env.VUE_APP_CRYPTO_CONTRACT_NFT_4.toLowerCase():
            return 4
        case process.env.VUE_APP_CRYPTO_CONTRACT_NFT_5.toLowerCase():
            return 5
        case process.env.VUE_APP_CRYPTO_CONTRACT_NFT_6.toLowerCase():
            return 6
        case process.env.VUE_APP_CRYPTO_CONTRACT_NFT_7.toLowerCase():
            return 7
    }
}
